<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-tabs>
          <v-tab v-if="$root.role === 'ADMIN'" to="/projects">Projects</v-tab>
          <v-tab v-if="$root.role === 'ADMIN'" to="/users">Users</v-tab>
          <v-tab v-if="$root.role === 'ADMIN'" to="/alerts">Alerts</v-tab>
          <v-tab v-if="$root.role === 'ADMIN'" to="/contacts">Contacts</v-tab>
          <v-tab v-if="$root.role === 'ADMIN'" to="/logs">Logs</v-tab>
          <v-tab v-if="$root.role === 'USER'" to="/users/alerts">Alerts</v-tab>
          <v-tab class="ml-auto" v-if="$root.token" @click="$root.exit()">Exit</v-tab>
        </v-tabs>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  mounted() {
    if (this.$root.token) {
      this.$root.getJSON({
        url: `${window.env.API}/api/auth/info`,
      })
          .then((res) => {
            this.$root.role = res.role;
            this.$root.login = res.login;

            if (this.$route.name === 'index') {
              switch (this.$root.role) {
                case 'ADMIN':
                  this.$router.push('/projects');
                  break;
                case 'USER':
                  this.$router.push('/users/alerts');
                  break;
              }
            }
          });
    } else {
      if (this.$route.name !== 'login') {
        this.$root.goLogin();
      }
    }
  },

  data: () => ({
    //
  }),

  methods: {},
};
</script>
