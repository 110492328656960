import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

function getToken() {
  return window.localStorage.getItem('token');
}

function setToken(token) {
  window.localStorage.setItem('token', token);
}

function removeToken() {
  window.localStorage.removeItem('token');
}

/*router.beforeEach((to, from, next) => {
  if (to.meta.auth && !getToken()) {
    next({
      name: 'login',
    });
  } else if (to.meta.admin && getRole() !== 'ADMIN') {
    next({
      name: 'login',
    });
  } else if (to.meta.user && getRole() !== 'USER') {
    next({
      name: 'login',
    });
  } else {
    next();
  }
});*/

new Vue({
  router,
  vuetify,
  mounted() {

  },
  data() {
    return {
      token: getToken(),
      role: null,
      login: null,
    };
  },
  methods: {
    getToken,
    setToken,
    removeToken,
    goLogin() {
      this.$router.push('/login');
    },
    postJSON(request) {
      return fetch(request.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`,
        },
        body: JSON.stringify(request.body),
      })
        .then((res) => {
          if (!res.ok) {
            switch (res.status) {
              case 401:
                this.exit();
                break;
              default:
                throw new Error(res.statusMessage);
            }
          }
          return res.json();
        });
    },
    getJSON(request) {
      return fetch(request.url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            switch (res.status) {
              case 401:
                this.exit();
                break;
              default:
                throw new Error(res.statusMessage);
            }
          }
          return res.json();
        });
    },
    exit() {
      this.removeToken();
      this.token = null;
      this.role = null;
      this.login = null;
      this.goLogin();
    },
  },
  render: h => h(App),
}).$mount('#app');
