import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/IndexView.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/ProjectsView.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/UsersView.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () => import(/* webpackChunkName: "alerts" */ '../views/AlertsView.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/ContactsView.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import(/* webpackChunkName: "logs" */ '../views/LogsView.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/users/alerts',
    name: 'users.alerts',
    component: () => import(/* webpackChunkName: "users.alerts" */ '../views/UsersAlertsView.vue'),
    meta: {
      user: true,
    },
  },
];

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
});

export default router;
